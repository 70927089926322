<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-12, .cls-13, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#glass-pattern);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#glass-pattern);
      }

      .cls-9 {
        fill: url(#glass-pattern);
      }

      .cls-10 {
        fill: url(#glass-pattern);
      }

      .cls-11 {
        fill: url(#glass-pattern);
      }

      .cls-12 {
        fill: url(#glass-pattern);
      }

      .cls-13 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="inox-gradient" x1="70.81" y1="245.84" x2="70.81" y2="54.96" xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient" x1="70.81" y1="210.49" x2="70.81" y2="90.31" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="glass-gradient-2" data-name="glass-gradient" x1="63.45" y1="83.37" x2="63.45" y2="61.92" xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-3" data-name="glass-gradient" x1="78.17" y1="83.37" x2="78.17" y2="61.92" xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-4" data-name="glass-gradient" x1="70.81" y1="70.64" x2="70.81" y2="59.91" xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-5" data-name="glass-gradient" x1="70.81" y1="85.36" x2="70.81" y2="74.65" xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-6" data-name="glass-gradient" x1="63.45" y1="238.89" x2="63.45" y2="217.44" xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-7" data-name="glass-gradient" x1="78.17" y1="238.89" x2="78.17" y2="217.44" xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-8" data-name="glass-gradient" x1="70.81" y1="240.89" x2="70.81" y2="230.16" xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-9" data-name="glass-gradient" x1="70.81" y1="226.17" x2="70.81" y2="215.44" xlink:href="#glass-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="V10">
      <polyline id="inox" 
                v-if="showInox"
                data-name="inox" 
                class="cls-4" 
                :points="`${doorLeftWidth1 + 53.14} ${doorTopHeight1 + 245.84} ${doorLeftWidth1 + 88.49} ${doorTopHeight1 + 245.84} ${doorLeftWidth1 + 88.49} ${doorTopHeight1 + 54.96} ${doorLeftWidth1 + 53.14} ${doorTopHeight1 + 54.96} ${doorLeftWidth1 + 53.14} ${doorTopHeight1 + 245.84}`"/>
      <rect filter="url(#inset-shadow)" id="glass" class="cls-5" :x="`${doorLeftWidth1 + 58.09}`" :y="`${doorTopHeight1 + 90.31}`" width="25.45" height="120.18"/>
      <polyline filter="url(#inset-shadow)" id="glass-2" 
                data-name="glass" 
                class="cls-6" 
                :points="`${doorLeftWidth1 + 68.81} ${doorTopHeight1 + 72.64} ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 61.92} ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 83.37} ${doorLeftWidth1 + 68.81} ${doorTopHeight1 + 72.64}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-3" 
                data-name="glass" 
                class="cls-7" 
                :points="`${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 61.92} ${doorLeftWidth1 + 72.81} ${doorTopHeight1 + 72.64} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 83.37} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 61.92}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-4" 
                data-name="glass" 
                class="cls-8" 
                :points="`${doorLeftWidth1 + 70.81} ${doorTopHeight1 + 70.64} ${doorLeftWidth1 + 81.54} ${doorTopHeight1 + 59.91} ${doorLeftWidth1 + 60.09} ${doorTopHeight1 + 59.91} ${doorLeftWidth1 + 70.81} ${doorTopHeight1 + 70.64}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-5" 
                data-name="glass" 
                class="cls-9" 
                :points="`${doorLeftWidth1 + 70.81} ${doorTopHeight1 + 74.65} ${doorLeftWidth1 + 60.09} ${doorTopHeight1 + 85.36} ${doorLeftWidth1 + 81.54} ${doorTopHeight1 + 85.36} ${doorLeftWidth1 + 70.81} ${doorTopHeight1 + 74.65}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-6" 
                data-name="glass" 
                class="cls-10" 
                :points="`${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 238.9} ${doorLeftWidth1 + 68.81} ${doorTopHeight1 + 228.17} ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 217.44} ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 238.9}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-7" 
                data-name="glass" 
                class="cls-11" 
                :points="`${doorLeftWidth1 + 72.81} ${doorTopHeight1 + 228.17} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 238.9} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 217.44} ${doorLeftWidth1 + 72.81} ${doorTopHeight1 + 228.17}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-8" 
                data-name="glass" 
                class="cls-12" 
                :points="`${doorLeftWidth1 + 81.54} ${doorTopHeight1 + 240.89} ${doorLeftWidth1 + 70.81} ${doorTopHeight1 + 230.16} ${doorLeftWidth1 + 60.09} ${doorTopHeight1 + 240.89} ${doorLeftWidth1 + 81.54} ${doorTopHeight1 + 240.89}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-9" 
                data-name="glass" 
                class="cls-13" 
                :points="`${doorLeftWidth1 + 60.09} ${doorTopHeight1 + 215.44} ${doorLeftWidth1 + 70.81} ${doorTopHeight1 + 226.17} ${doorLeftWidth1 + 81.54} ${doorTopHeight1 + 215.44} ${doorLeftWidth1 + 60.09} ${doorTopHeight1 + 215.44}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
